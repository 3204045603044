<template>
  <div class="top-category">
    <div class="container" >
      <!-- 面包屑 -->
      <!-- <XtxBread>
        <XtxBreadItem to="/">首页</XtxBreadItem>
        <Transition name="fade-right" mode="out-in">
          <XtxBreadItem :key="topCategory.id">{{topCategory.name}}</XtxBreadItem>
        </Transition>
      </XtxBread> -->
      <!-- 轮播图 -->
      <!-- <XtxCarousel auto-play :sliders="sliders" style="height:500px" /> -->
      <!-- 所有二级分类 -->
      <!-- <div class="sub-list">
        <h3>全部分类</h3>
        <ul>
          <li v-for="sub in topCategory.children" :key="sub.id">
            <RouterLink :to="`/category/sub/${sub.id}`">
              <img :src="sub.picture" >
              <p>{{sub.name}}</p>
            </RouterLink>
          </li>
        </ul>
      </div> -->
      <!-- 各个分类推荐商品 -->
      <!-- <div class="ref-goods" v-for="sub in subList" :key="sub.id">
        <div class="head">
          <h3>- {{sub.name}} -</h3>
          <p class="tag">温暖柔软，品质之选</p>
          <XtxMore :path="`/category/sub/${sub.id}`" />
        </div>
        <div class="body">
          <GoodsItem v-for="goods in sub.goods" :key="goods.id" :goods="goods" />
        </div>
      </div> -->
      <div v-if="level=='true'">
        <div class="top">
            <div class="title">
              {{ titleList.name }}:
            </div>
            <div class="secondTit">
              <div style="width: 80px;height: 50px;" @click="getAllPro({pid:titleList.id})" :class="{active:activeType && !activeType.length}">全部</div>
              <div 
              v-for="item in titleList.childList" 
              :key="item.id" 
              @click="getPro({secondLevelId:item.id},item)"
              :class="{active:isActive(item)}"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="str"></div>
          <div v-if="secondList.length>0">
              <div class="top top1">
                <div class="title" style="padding-left: 60px;">
                  分类：
                </div>
                <div class="secondTit" >
                  <div style="width: 80px;" @click="getAllCatePro({pid:titleList.id})" :class="{active:activeThirdType && !activeThirdType.length}">全部</div>
                  <div v-for="item in secondList" :key="item.id" @click="getCatePro({id:item.id,secondLevelId:item.pid})" :class="{active:isActive2(item)}">
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="str"></div>
          </div>
      </div>
      <div v-else>
        <div style="font-size: 22px;">{{ level }}:</div>
        <div class="str"></div>
      </div>

      <div>
        
        <div class="product" v-if="list.length>0">
        <div v-for="item in list" :key="item.id" class="probox" @click="gotopage(`/product/${item.id}`)">
          <div class="imgbox">
            <img :src="item.image" alt="">
          </div>
          <div class="txtbox">
            <h4>¥{{ item.price }}</h4>
            <h5>{{ item.name }}</h5>
            <h5>{{ item.organName }}</h5>
          </div>
        </div>
      </div>
      <div v-else style="font-size: 24px;text-align: center;margin: 80px;">  <a-empty description="暂无商品" /></div>

      </div>
      <div class="bottom">
        <div class="paginationBox">
          <a-pagination
            @change="paginationChange"
            :total="total"
            :defaultPageSize="filter.size"
            :show-total="total => `共 ${total} 条`"
            hideOnSinglePage
            v-model:current="current1"
     
          />
          
        </div>
      </div>


    </div>
    <!-- <div v-else>暂无数据</div> -->
  </div>
</template>
<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { postCategory } from '@/api/home'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import GoodsItem from './components/goods-item'
import { gotopage } from '@/hooks'

export default {
  name: 'TopCategory',
  components: { GoodsItem },
  setup () {
    const store = useStore()
    const route = useRoute()
    const total = ref(0)
    const filter = reactive({
      page: 1,
      size: 12
    });
    const pid = route.params.id
    const level = route.params.level
    
    const titleList = ref([])
    const list = ref([])
    const secondList = ref([])
    const postData = reactive({
        page:1,
        size:12,
        pid:pid,
        secondLevelId:'',
        id:''
      })
    if(level !== 'true'){
      console.log(level);
      postData.id = pid
    }


    const active = ref(true)
    const activeType = ref([])
    const isActive = (item)=>{
      // console.log(item);
      return activeType.value.includes(item.id)
    }

    //分页
    const current1 = ref(1)
    const paginationChange = (num)=>{
      console.log(num);
      postData.page = num

      getList()
    }

    const getAllPro = (data) =>{
      console.log('alldata----',data);
      postData.pid = data.pid
      postData.secondLevelId = ''
      postData.id = ''
      postData.page = 1
      activeType.value = []
      secondList.value=[]
      current1.value = 1

      getList()
    }


    const activeThirdType = ref([])
    const isActive2 = (item) =>{
      return activeThirdType.value.includes(item.id)
    }
    const getAllCatePro = (data) =>{
      console.log('allcatedata----',data);
      let data2 =JSON.parse(localStorage.getItem('proitem'))
      console.log('allcatedata2',data2);
      if(data2.id){
        if(activeThirdType.value.includes(data.secondLevelId)){
          return
        }
        activeThirdType.value = []
        postData.secondLevelId = data2.id
        postData.id = ''
        current1.value = 1
        postData.page = 1
      }
      getList()
    }


    
   
    const getCatePro = (data) =>{
      console.log('catepro----',data);
      if(data.id){
        if(activeThirdType.value.includes(data.id)){
          return
        }
        activeThirdType.value = []
        activeThirdType.value.push(data.id)
        postData.secondLevelId = data.secondLevelId
        postData.id = data.id
        current1.value = 1
        postData.page = 1
      }else{
        // activeType.value = []
        // selectedThird.value = []
        activeThirdType = []
        postData.id = ''
        
      }

      getList()
    }

    
    const getPro = (data,item) =>{
  
      console.log('data----',data);
      console.log('item----',item);

      localStorage.setItem('prodata',JSON.stringify(data))
      // localStorage.setItem('proitem',JSON.stringify(item))

      if(item){
        secondList.value = item.childList
      }
 
      if(data.secondLevelId){
        postData.secondLevelId = data.secondLevelId
        if(activeType.value.includes(data.secondLevelId)){
          return
        }
        activeType.value = []
        activeType.value.push(data.secondLevelId)

      }
    
      postData.page = 1
      
      getList()
    }

    const getList = ()=>{
      postCategory(postData).then(res=>{
        console.log(res);
        if(res.data.proCategoryCacheVos){
          titleList.value = res.data.proCategoryCacheVos[0]
        }
        list.value = res.data.products.records
        total.value = res.data.products.total
        
      })
    }

    onMounted(()=>{
      getList()
    })

    return {
      paginationChange,
      total,
      filter,
      list,
      titleList,
      getPro,
      secondList,
      isActive,
      activeType,
      active,
      gotopage,
      getAllPro,
      getCatePro,
      getAllCatePro,
      activeThirdType,
      isActive2,
      current1,
      level
    }
  }
}
</script>
<style scoped lang="less">
.top-category {
  .container{
    width: 1246px;
    // height: 1500px;
    background-color: #fff;
    padding: 20px;
    clear: both;
    .str{
      border: 1px solid lightgray;
      margin: 10px 0;
    }
    .top{
      display: flex;
      .title{
        font-size: 22px;
        // width: 220px;
        padding-top: 6px;
        clear: both;
      }
      .secondTit{
        margin-left:10px ;
        font-size: 18px;
        flex:1;
        div{
          height: 50px;
          float: left;
          line-height: 50px;
          text-align: center;
          padding-left: 10px;
          padding-right: 10px;
          margin-bottom: 10px;
          box-sizing: border-box;
          color: #333;
          &:hover{
            color: #d90d0d;
            cursor: pointer;
          }
          &.active{
            background-color: #d90d0d;
            color: #fff;
          }

        }
      
      }
    }

    .top1{

    }

    .product{
      padding: 20px;
     
      .probox{
        float: left;
        width: 250px;
        height: 360px;
        margin: 20px;
        // border: 1px solid grey;
          .imgbox{
            width: 100%;
            height: 240px;
            text-align: center;
            img{
              width: 250px;
              height: 240px;
              
            }
          }
          .txtbox{
            height: 120px;
            background-color: #f5f5f5;
            text-align: center;
            overflow: hidden;
            h4{
              color: #d90d0d;
              font-size: 18px;
              margin-top: 10px;
            }
            h5{
              font-size: 12px;
              color: grey;
              margin-top: 5px;
            }
          }

          &:hover{
            cursor: pointer;
            transform: scale(1.1);
            transition: all .5s;
          }
      }
    }



    .bottom{
      clear: both;
      .paginationBox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
        
      :deep(.ant-pagination-item-active) {
          border-color: #d90d0d;
          a {
            color: #d90d0d;
          }
        }
    }
  }
  }



  
  // h3 {
  //   font-size: 28px;
  //   color: #666;
  //   font-weight: normal;
  //   text-align: center;
  //   line-height: 100px;
  // }
  // .sub-list {
  //   margin-top: 20px;
  //   background-color: #fff;
  //   ul {
  //     display: flex;
  //     padding: 0 32px;
  //     flex-wrap: wrap;
  //     min-height: 160px;
  //     li {
  //       width: 168px;
  //       height: 160px;
  //       a {
  //         text-align: center;
  //         display: block;
  //         font-size: 16px;
  //         img {
  //           width: 100px;
  //           height: 100px;
  //         }
  //         p {
  //           line-height: 40px;
  //         }
  //         &:hover {
  //           color: @xtxColor;
  //         }
  //       }
  //     }
  //   }
  // }
  // // 推荐商品
  // .ref-goods {
  //   background-color: #fff;
  //   margin-top: 20px;
  //   position: relative;
  //   .head {
  //     .xtx-more {
  //       position: absolute;
  //       top: 20px;
  //       right: 20px;
  //     }
  //     .tag {
  //       text-align: center;
  //       color: #999;
  //       font-size: 20px;
  //       position: relative;
  //       top: -20px;
  //     }
  //   }
  //   .body {
  //     display: flex;
  //     justify-content: flex-start;
  //     flex-wrap: wrap;
  //     padding: 0 65px 30px;
  //   }
  // }
}
</style>
